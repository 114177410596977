import React, { useEffect, useState, useRef } from 'react'
import dynamic from 'next/dynamic'
import { useSelector } from 'react-redux'
import { AutoSizer } from 'react-virtualized'
import CoinsFilterLayout from 'src/layouts/CoinsFilterLayout'
import { MainLayout } from 'src/layouts'
import NewsModal from '../../components/NewsModal'
import Image from 'next/image'
import { Loader } from 'src/components/Loader'
import { FooterMobile } from 'src/components/FooterMobile'
import { ShareMapHeader } from 'src/components/ShareMapHeader'
import {
  getFilteredMapDataTree,
  getCoinsGroup,
  getCoinsRange,
  getCoinsExceptions,
  getCoinsDisplay,
  getCoinsActivePeriod,
  getCoinsDependsOn,
  getCoinsTop,
  getCurrentCurrencySelector,
  getGlobals,
  getFullScreenState,
  getCoinsGlobalSelector,
} from 'src/redux/selectors'
import { TREEMAP_ID, ENTITY, PERIODS } from 'src/consts'
import { formatGlobals } from 'src/utils/currency'
import useFetchMapData from 'src/hooks/useFetchMapData'
import useWidth from 'src/hooks/useWidth'
import styles from './styles.module.scss'
import { useRouter } from 'next/router'
import { intl } from 'src/i18n'
import { TestSidebar } from 'src/components/RightSidebar/TestSidebar'
import { TableView } from 'src/components/TableView'
import { RightSidebar } from 'src/components/RightSidebar'
import { useConnectedState } from 'src/components/TableView/hooks/redux'
import { useDispatch } from 'react-redux'
import { setSharedCurrency } from 'src/redux/thunks'
import AdSidebar from 'src/components/AdSidebar'

const TreeMap = dynamic(() => import('src/components/TreeMap'), { ssr: false })

const title = intl.t('main.seo.title')
const description = intl.t('main.seo.description')

const Main = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  return (
    <MainLayout title={title} description={description} isHeatmap>
      <Test modalOpen={modalOpen} toggleModal={toggleModal} />
    </MainLayout>
  )
}

const Test = ({ modalOpen, toggleModal }) => {
  const router = useRouter()
  const activePeriod = useSelector(getCoinsActivePeriod)
  const dataType = PERIODS.dataKeys[activePeriod]
  const currency = useSelector(state => getCurrentCurrencySelector(state))
  const globals = useSelector(getGlobals)
  const [isLoading, setIsLoading] = useState(true)
  const { dominance } = formatGlobals(globals, currency)
  const fullScreen = useSelector(getFullScreenState)
  const { isMobile } = useWidth()
  const isShare = router.asPath.includes('getScreen')
  const [isSidebarOpened, setIsSidebarOpened] = useState(true)
  const layoutRef = useRef(null)
  const connectedState = useConnectedState(ENTITY.COIN)
  const coinsData = connectedState.data
  const globalData = useSelector(getCoinsGlobalSelector)

  const onPageChange = () => {
    layoutRef.current?.scrollToTop()
  }
  useEffect(() => {
    setIsLoading(false)
  }, [])

  useFetchMapData({ entity: ENTITY.COIN })

  const viewProps = {
    id: TREEMAP_ID,
    isMobile,
    widget: undefined,
    topLeaderboardHeight: undefined,
    currency,
    dataType,
    autoUpdate: false,
    baseValue: useSelector(getCoinsDependsOn),
    activePeriod,
    filteredTree: useSelector(getFilteredMapDataTree),
    slices: useSelector(getCoinsDisplay),
    topLoosersAndGainers: useSelector(getCoinsTop),
    blockSizeRange: useSelector(getCoinsRange),
    exceptions: useSelector(getCoinsExceptions),
    entity: ENTITY.COIN,
    groupFilter: useSelector(getCoinsGroup),
    dominance,
  }

  return (
    <>
      <h1 className={styles.seoTags}>
        Visualize the Crypto Market with COIN360&apos;s Comprehensive Heatmap
      </h1>
      <h2 className={styles.seoTags}>Discover Current Cryptocurrency Prices</h2>
      <h2 className={styles.seoTags}>
        Explore Market Capitalizations of Different Coins
      </h2>
      {/* Changed !fullScreen && !isMobile && !isShare && to !fullScreen && !isShare && for now so we can show the filter in mobile 29/2  */}
      {!fullScreen && !isShare && (
        <CoinsFilterLayout
          classNames={[styles.filters]}
          isSidebarOpened={isSidebarOpened}
        />
      )}
      <div className={styles.contentPage}>
        <div className={styles.content}>
          {isShare && <ShareMapHeader />}
          <div className={styles.container}>
            <div className={styles.mapbox}>
              <div className={styles.buttons}>
                {/* <ShareButton className={styles.share}></ShareButton> */}
              </div>
              <AutoSizer
                defaultWidth={viewProps.isMobile ? 320 : 1280}
                defaultHeight={1024}
              >
                {({ height, width }) => {
                  return (
                    <TreeMap width={width} height={height} {...viewProps} />
                  )
                }}
              </AutoSizer>
            </div>
          </div>
          {/* <div className={styles.newsButtonContainer}>
            {modalOpen && <NewsModal onClose={toggleModal} />}{' '}
            <button className={styles.newsButton} onClick={toggleModal}>
              Live News
              <Image
                className={styles.closeIcon}
                src='/assets/icons/icon-arrow-up-new.svg'
                width='15px'
                height='15px'
                alt='Open arrow'
              />
            </button>{' '}
          </div> */}
        </div>
        {!isShare && !isMobile && (
          <RightSidebar setIsSidebarOpened={setIsSidebarOpened} />
        )}
      </div>

      <TableView
        currency={currency}
        onPageChange={onPageChange}
        activePeriod={activePeriod}
        entity={ENTITY.COIN}
        entitiesData={coinsData}
        globalData={globalData}
      />

      <Loader isActive={isLoading} />
      {/* {isMobile && !isShare && <FooterMobile />} */}
    </>
  )
}

export default Main
